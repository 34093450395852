<div class="clr-row clr-justify-content-center margin-0">
  <div class="clr-col-xl-8 clr-col-lg-9 clr-col-md-10 clr-col-sm-11 clr-col-xs-12">
    <div class="page-content">

      <h3>{{__('active-accounts')}}</h3>

      <table class="active-user-list">
        <thead>
        <tr>
          <th class="col-first-name">{{__('first-name')}}</th>
          <th class="col-last-name">{{__('last-name')}}</th>
          <th class="col-keycloak-id">KeycloakId</th>
          <th class="col-activate-button"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let user of invitedUsers">
          <td class="col-first-name">{{ user.firstName }}</td>
          <td class="col-last-name">{{ user.lastName }}</td>
          <td class="col-keycloak-id">{{ user.keycloakId }}</td>
          <td class="col-activate-button" (click)="toggleChatbotInvite(user)"><button>{{__('deactivate')}}</button></td>
        </tr>
        </tbody>
      </table>

      <div class="information-text" *ngIf="showNewToggledUserText">
        {{__('jay-toggled-1')}} {{ newToggledUser.firstName }} {{ newToggledUser.lastName }} {{__('jay-toggled-2')}}
      </div>

      <div class="search-input-container">
        <input [(ngModel)]="searchInputText"
               maxlength="100" placeholder="{{__('search-placeholder')}}"
               title="Search"
               type="search">

        <button *ngIf="searchInputText.length >= 3" (click)="searchUsers(searchInputText)" class="id37-btn id37-btn-sm id37-btn-secondary">
          <img alt="search" src="assets/icons/arrow_link_12x12.svg"/>
        </button>
      </div>

      <div class="information-text" *ngIf="hasSearched">
        <ng-container [ngSwitch]="foundUsers.length">
          <ng-container *ngSwitchCase="0">{{ foundUsers.length }} {{__('results')}}</ng-container>
          <ng-container *ngSwitchCase="1">{{ foundUsers.length }} {{__('result')}}</ng-container>
          <ng-container *ngSwitchDefault>{{ foundUsers.length }} {{__('results')}}</ng-container>
        </ng-container>
      </div>

      <table class="result-list" *ngIf="foundUsers.length != 0">
        <thead>
          <tr>
            <th class="col-first-name">{{__('first-name')}}</th>
            <th class="col-last-name">{{__('last-name')}}</th>
            <th class="col-keycloak-id">KeycloakId</th>
            <th class="col-activate-button"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of foundUsers">
            <td class="col-first-name">{{ user.firstName }}</td>
            <td class="col-last-name">{{ user.lastName }}</td>
            <td class="col-keycloak-id">{{ user.keycloakId }}</td>
            <td class="col-activate-button" (click)="toggleChatbotInvite(user)"><button>{{__('activate')}}</button></td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</div>