import {Component, OnInit} from '@angular/core';
import {UserSearchService} from '../../user/user-search.service';
import {BasicUser} from '../../user/basic-user';
import {TranslatorService} from '../../translation/translator.service';
import {AdminService} from '../admin.service';

@Component({
  selector: 'app-chatbot-access',
  templateUrl: './chatbot-access.component.html',
  styleUrl: './chatbot-access.component.scss'
})

export class ChatbotAccessComponent implements OnInit{

  userTargetFilter: string = 'ALL';
  searchInputText: string = '';
  foundUsers: BasicUser[] = [];
  hasSearched: boolean = false;
  invitedUsers: BasicUser[] = [];
  newToggledUser: BasicUser;
  showNewToggledUserText: boolean = false;

  constructor(private userSearchService: UserSearchService,
              private translatorService: TranslatorService,
              private adminService: AdminService) {
  }

  ngOnInit(){
    this.getInvitedUsers();
  }

  searchUsers(searchInput: string){
    if (searchInput.length >= 3) {
      this.userSearchService.searchUsers(searchInput, this.userTargetFilter)
        .subscribe((list: BasicUser[]) => {
          /*
            Filter out users that have Jay activated through acquisition accounts (users shown on the active user table)
            so that they don't appear in the search results.
             */
          this.foundUsers = list.filter(searchedUser =>
            !this.invitedUsers.some(activeUser =>
              activeUser.keycloakId === searchedUser.keycloakId));

          // Show number of results
          this.hasSearched = true;
        });
    }
  }

  toggleChatbotInvite(user: BasicUser) {
    this.adminService.toggleChatbotInvite(user).subscribe(
      () => {

        // Show user activation message
        this.newToggledUser = user;
        this.showNewToggledUserText = true;
        setTimeout(() => {
          this.showNewToggledUserText = false;
        }, 10000);

        // Refresh the active user table after a new user is added
        this.getInvitedUsers();
      }
    );
  }

  getInvitedUsers(){
    this.adminService.getInvitedUsers().subscribe(
      invitedUsers => this.invitedUsers = invitedUsers
    );
  }

  __(key: string): string {
    return this.translatorService.translate('chatbot-access.' + key);
  }
}
