import {Component, HostListener, Input, OnInit} from '@angular/core';
import {Order} from '../../order';
import {OrderStatus} from '../../order-status';
import {TranslatorService} from '../../../translation/translator.service';
import {OrderService} from '../../order.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ChatbotUserStatus} from '../../../user-data/chatbot-user-status';
import {TableColumn, TableColumnButtonIcon, TableRow} from '../../../id37/table/webapp-table/webapp-table.component';
import {JsDatePipe} from '../../../datetime/js-date.pipe';
import {CurrentUser} from '../../../user/current-user.service';
import {CreateWebAppTableColumnsUtils} from '../../../id37/table/create-web-app-table-columns-utils';
import {map} from 'rxjs';

const BREAKPOINT_VALUE = 768;
const WEB_APP_TABLE_HEADER_COLUMNS = [
  'orders.date',
  'orders.product',
  'orders.status',
  'orders.amount',
  'orders.invoices'
];

const BUTTON_ICON_IMAGE_URL = 'assets/icons/download_24x24.svg';
const BUTTON_ALT = 'orange arrow';

@Component({
  selector: 'app-orders-invoice',
  templateUrl: './orders-invoice.component.html',
  styleUrl: './orders-invoice.component.scss'
})
export class OrdersInvoiceComponent implements OnInit{

  @Input()
    chatbotUserStatus: ChatbotUserStatus;
  @Input()
    orders: Order[];
  orderIdToHighlight: string | undefined;
  webappTableHeaderColumns: string[] = WEB_APP_TABLE_HEADER_COLUMNS;
  webappTableBodyRows: TableRow[] = [];
  buttonClickedParams: (number | string)[][] = [];
  errorOrderId: string[] = [];

  hasInvoice: boolean = false;
  isErrorStatus: boolean = false;
  isUnfinished: boolean = false;
  innerWidth: any;

  constructor(private translatorService: TranslatorService,
              private orderService: OrderService,
              private router: Router,
              private route: ActivatedRoute,
              private currentUser: CurrentUser) {
  }

  ngOnInit() {
    this.getOrderData();
    this.route.params.subscribe(params => {
      this.orderIdToHighlight = params.orderId;
    });
    this.innerWidth = window.innerWidth;

  }

  private initializeTableData() {
    const tableRows: TableRow[] = [];
    this.buttonClickedParams = [];
    this.orders?.forEach(order => {
      this.buttonClickedParams.push([order.orderId, order.invoice.invoiceNr]);
      const tableRow = this.createWebAppTableColumns(order);
      tableRows.push(tableRow);
    });
    this.webappTableBodyRows = tableRows;
  }

  private createWebAppTableColumns(order: Order) {
    const tableColumns: TableColumn[] = [];
    const formattedCreationDate: string = this.formatCreationDate(order);

    tableColumns.push(CreateWebAppTableColumnsUtils
      .createTableColumn(formattedCreationDate, CreateWebAppTableColumnsUtils.BOLD_COLOR_CSS_CLASS));

    tableColumns.push(CreateWebAppTableColumnsUtils.createTableColumn(this.getProduct(order.positions[0].product)));

    const productStatusKey: string = this.getProductStatus(order.status);
    const productStatus: string = this.__('invoices.' + productStatusKey);
    tableColumns.push(CreateWebAppTableColumnsUtils.createTableColumn(productStatus));
    const finalPrice: string = order.positions[0].finalPriceAsNumber?.toString();
    tableColumns.push(CreateWebAppTableColumnsUtils.createTableColumn(finalPrice + '€'));
    const buttonIcon: TableColumnButtonIcon = CreateWebAppTableColumnsUtils
      .createTableColumnButtonIcon(BUTTON_ICON_IMAGE_URL, BUTTON_ALT, CreateWebAppTableColumnsUtils.BUTTON_ICON_CSS_CLASS);

    const buttonLabel: string = this.__('invoices.no') + order.invoice.invoiceNr;
    tableColumns.push(CreateWebAppTableColumnsUtils.createTableColumn(buttonLabel,
      CreateWebAppTableColumnsUtils.BUTTON_CSS_CLASS, CreateWebAppTableColumnsUtils.COLUMN_INCLUDES_BUTTON, buttonIcon));
    const tableRow: TableRow = {
      columns: tableColumns
    };
    return tableRow;
  }

  private formatCreationDate(order: Order): string {
    const jsDatePipe = new JsDatePipe(this.currentUser);
    return jsDatePipe.transform(order.creationDate);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  isMobileView() {
    return this.innerWidth <= BREAKPOINT_VALUE;
  }

  getOrderData() {
    this.orderService.loadOrdersForCurrentUser().pipe(
      map(orders => orders.filter(order => this.canShowOrder(order))),
      map(orders => orders.sort((a, b) =>
        new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
      ))
    ).subscribe(orders => {
      this.orders = orders;
      this.initializeTableData();
    });
  }

  private canShowOrder(order: Order) {
    if (order.status === OrderStatus.ERROR){
      this.isErrorStatus = true;
    }
    return order.status === OrderStatus.COMPLETED || order.status === OrderStatus.UNSUBSCRIBED ||
      order.status === OrderStatus.ERROR || order.status === OrderStatus.SUBSCRIPTION_TERMINATED;
  }

  getProduct(product: string): string {
    if (this.isUnfinished) {
      return this.__('products.unfinishedOrder');
    }
    switch (product) {
      case 'ID37_TEST':
        return this.__('products.id37Test');
      case 'ID37_TEST_WITH_COACHING':
        return this.__('products.id37TestWithCoaching');
      case 'ID37_CHATBOT':
        return this.__('products.id37Chatbot');
      default:
        return this.__('products.unknownProduct');
    }
  }

  getProductStatus(status: string): string {
    switch (status) {
      case OrderStatus.INITIALIZED:
        return 'initialized';
      case OrderStatus.PENDING:
        return 'pending';
      case OrderStatus.CANCELED:
        return 'canceled';
      case OrderStatus.COMPLETED:
        return 'completed';
      case OrderStatus.RETRYING:
        return 'retrying';
      case OrderStatus.UNSUBSCRIBED:
        return 'unsubscribed';
      case OrderStatus.SUBSCRIPTION_TERMINATED:
        return 'subscription_terminated';
      default:
        return 'error';
    }
  }

  downloadInvoicePdf(params: (string | number)[]): void {
    this.orderService.downloadInvoicePdf(params[0] as string, params[1] as number);
  }

  __(key: string): string {
    return this.translatorService.translate('orders.' + key);
  }
}
